<template>
  <div class="Shop_SubmitApprovalList">
    <el-row :gutter="20">
      <el-col :span="6" :offset="1">
        <div class="div">商铺信息</div>
        <el-card class="deepCard">
          <el-descriptions class="margin-top" :column="1" size="medium" border>
            <el-descriptions-item label="商铺名称">
              <el-tag v-if="ShopData.ShopName">
                {{ ShopData.ShopName }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="商铺编号">
              <el-tag v-if="ShopData.ShopCode">
                {{ ShopData.ShopCode }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="商铺面积">
              <el-tag v-if="ShopData.ShopArea">
                {{ ShopData.ShopArea }}㎡
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="工程编号">
              <el-tag v-if="ShopData.ProjectCode">
                {{ ShopData.ProjectCode }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="业态类型">
              <el-tag v-if="ShopData.ShopFormatClass">
                {{ ShopData.ShopFormatClass }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="版本">
              <el-tag v-if="ShopData.ChangeVersion">
                {{ ShopData.ChangeVersion }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="备注">
              <el-tag v-if="ShopData.Remark">{{
                ShopData.Remark
              }}
              </el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </el-card>
      </el-col>
      <el-col :span="8">
        <div class="div">
          设备/材料报审
          <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px;"
            @click="addAuditOpen(1)"></el-button>
        </div>
        <el-card class="deepCard">

          <div>
            <div v-for="(item) in auditMaterialsInfoList" :key="item.id"
              style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;"
              class="auditMaterialsInfoList" @click="editAuditOpen(item, 1)">
              <el-row style="display: flex;align-items: center;font-size: 14px;">
                <div style="width: 84px;text-align: right;" :span="4">
                  <el-tag style="font-weight: bold;letter-spacing: 1px;margin-left: 10px;text-align: center;"
                    effect="plain">{{ item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : '' }}
                  </el-tag>
                </div>
                <div style="width: calc(100% - 264px);margin-left: 10px;">
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单名称：</span>
                      <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单编号：</span>
                      <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单批次：</span> 第 <span
                        style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch }}</span> 批次
                    </el-col>
                  </el-row>
                </div>
                <div style="width: 160px;margin-left: 10px;text-align: center;">
                  <el-row>
                    <el-button size="mini" type="primary" @click.stop="AuditOpen(item, 1)" v-if="item.Status == 0">提审GO
                    </el-button>
                    <el-button size="mini" type="primary" v-if="item.Status == 1" @click.stop="AuditOpen(item, 3)">已提审
                    </el-button>
                    <el-button size="mini" type="primary" icon="el-icon-delete" :disabled="item.Status != 0"
                      @click.stop="AuditDelete(item, 1)"></el-button>
                  </el-row>

                  <el-row style="margin-top: 10px;">
                    <span style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                      {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                    </span>
                  </el-row>
                </div>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <div class="div">
          深化图纸报审
          <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px;"
            @click="addAuditOpen(2)"></el-button>
        </div>
        <el-card class="deepCard">
          <div>
            <div v-for="(item) in auditImgInfoList" :key="item.id"
              style="border: 1px solid #D3CBCB;margin-top: 10px;border-radius: 10px;cursor: pointer;padding: 10px 0px;"
              class="auditMaterialsInfoList" @click="editAuditOpen(item, 2)">
              <el-row style="display: flex;align-items: center;font-size: 14px;">
                <div style="width: 84px;text-align: right;">
                  <el-tag style="font-weight: bold;letter-spacing: 1px;text-align: center;" effect="plain">{{
                    item.ApprovalType == 1 ? '设备材料' : item.ApprovalType == 2 ? '深化图纸' : ''
                  }}
                  </el-tag>
                </div>
                <div style="width: calc(100% - 264px);margin-left: 10px;">
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单名称：</span>
                      <span :title="item.ApprovalName">{{ item.ApprovalName }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单编号：</span>
                      <span :title="item.ApprovalCode">{{ item.ApprovalCode }}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col style="line-height: 1.5" class="ellipsis1">
                      <span style="font-weight: bold;">报审单批次：</span> 第 <span
                        style="font-weight: bold;color: #D1100B;">{{ item.ApprovalBatch }}</span> 批次
                    </el-col>
                  </el-row>
                </div>
                <div style="width: 160px;margin-left: 10px;text-align: center;">
                  <el-row>
                    <el-button size="mini" type="primary" @click.stop="AuditOpen(item, 2)" v-if="item.Status == 0">提审GO
                    </el-button>
                    <el-button size="mini" type="primary" v-if="item.Status == 1" @click.stop="AuditOpen(item, 3)">已提审
                    </el-button>
                    <el-button size="mini" type="primary" icon="el-icon-delete" :disabled="item.Status != 0"
                      @click.stop="AuditDelete(item, 2)"></el-button>
                  </el-row>
                  <el-row style="margin-top: 10px;">
                    <span style="text-align: center;color: #C0C4CC;font-size: 13px;margin-top: 5px">
                      {{ item.CreateTime ? item.CreateTime.substring(0, 19) : '' }}
                    </span>
                  </el-row>
                </div>
              </el-row>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="addAuditFlag" width="60%" append-to-body :show-close="false" :close="addAuditClose"
      custom-class="addAudit">
      <div slot="title">
        <div style="line-height: 33px;display: flex;justify-content: space-between;">
          <span style="font-size: 20px;font-weight: bold;color:#606266">{{
            addAuditType == 1 ? `${AuditTitle}设备/材料报审` :
              `${AuditTitle}深化图纸报审`
          }}</span>

          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;" src="@/assets/delete.png" alt=""
            @click="addAuditClose" />
        </div>
      </div>
      <el-form :model="addAuditFlagForm" :rules="addAuditFlagRules" ref="addAuditFlagForm" label-width="200px"
        style="width: 90%;font-weight: bold;" class="addAuditForm" v-loading.fullscreen.lock="addAuditLoadings"
        :disabled="addIsDisabled">
        <el-form-item label="商铺名称：">
          <el-input v-model="ShopData.ShopName" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="商铺编号：">

          <el-input v-model="ShopData.ShopCode" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'" prop="ApprovalName">
          <el-input v-model="addAuditFlagForm.ApprovalName" autocomplete="off" @input="changeApprovalName"></el-input>
        </el-form-item>
        <el-form-item :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'" prop="ApprovalCode">
          <el-input v-model="addAuditFlagForm.ApprovalCode" autocomplete="off">
            <template slot="prepend" v-if="addAuditType == 1">M-{{
              code ? code :
                padding1(this.auditMaterialsInfoList.length + 1, 4)
            }}-
            </template>
            <template slot="prepend" v-if="addAuditType == 2">S-{{
              code ? code : padding1(this.auditImgInfoList.length +
                1, 4)
            }}-
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="报审批次：" prop="ApprovalBatch">
          第
          <el-input-number v-model="addAuditFlagForm.ApprovalBatch" :min="1" :precision="0"></el-input-number>
          批次
        </el-form-item>
        <el-form-item label="报审单位：" prop="ReportingUnit">
          <el-input v-model="addAuditFlagForm.ReportingUnit" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="报审人：" prop="ReportingName">
          <el-input v-model="addAuditFlagForm.ReportingName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="报审日期：" prop="ReportingDate">
          <el-date-picker v-model="addAuditFlagForm.ReportingDate" type="datetime" placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关联附件：">
          <UploadValue v-model="addAuditUpFileData" @success="propValidateField" ref="UploadValue" :id="rowId"
            :disabled="addIsDisabled" @error="errorValidateField">
          </UploadValue>
        </el-form-item>
        <el-form-item label="审批单位或部门：" v-for="(item, index) in SignatoryUnitList" :key="index"
          style="position: relative;">
          <div style="display: flex;">
            <el-select v-model="item.ApprovalOrganName" clearable placeholder="请选择或手动填写" :disabled="addIsDisabled"
              filterable allow-create @change="changeSignatoryUnit" default-first-option>
              <el-option v-for="v in SignatoryUnitOptions" :key="v.DickValue" :label="v.DicValue"
                :value="v.DicValue"></el-option>
            </el-select>
            <div style="display: flex;width: 500px;margin-left: 10px;">
              <div style="width: 100px;font-size: 18px;font-weight: bold;">审核人：</div>
              <el-input v-model="item.ApprovalPhone" placeholder="请输入审核人手机号" :disabled="addIsDisabled"
                type="number"></el-input>
            </div>
          </div>

          <div
            style="position: absolute;right: -100px;top: 2px;width: 100px;display: flex;justify-content: space-around;">
            <el-button icon="el-icon-plus" type="primary" size="mini" circle style="font-size: 16px;"
              @click="addSignatory(index)" :disabled="addIsDisabled"></el-button>
            <el-button icon="el-icon-delete" type="primary" size="mini" circle style="font-size: 16px;"
              @click="delSignatory(index, item)" :disabled="SignatoryUnitOptionsLength || addIsDisabled"></el-button>
          </div>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addAuditClose">取 消</el-button>
        <el-button type="primary" @click="addAuditSubmit" v-loading="addAuditLoadings"
          v-if="!addIsDisabled">保存</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog :visible.sync="qrcodeVisible" width="400px" append-to-body :show-close="false" custom-class="addAudit">
      <div slot="title">
        <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 18px;">
          请将二维码发送至审核人员进行审核
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="qrcodeVisible = false" />
        </el-row>
      </div>
      <div style="text-align: center;">
        <vue-qr :text="qrcode" :logoScale="0.2" :size="300"></vue-qr>
      </div>
    </el-dialog> -->
    <el-dialog :visible.sync="qrcodeVisible" width="50%" append-to-body :show-close="false">
      <div slot="title">
        <el-row style="height: 33px;text-align: center;font-weight: bold;font-size: 18px;">
          请将二维码或链接转发至审核人员进行审核
          <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;float: right;"
            src="@/assets/delete.png" alt="" @click="qrcodeVisible = false" />
        </el-row>
      </div>
      <div style="display: flex;justify-content: space-around;align-items: center;">
        <div>
          <span>点击链接复制：</span><br>
          <span style="color: #D1100B;cursor: pointer;" @click="copyUrl(qrcode)"> {{ qrcode }}</span>
        </div>
        <div>
          <vue-qr :text="qrcode" :logoScale="0.2" :size="200"></vue-qr>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrcodeVisible = false">关闭</el-button>

      </span>
    </el-dialog>
    <el-dialog :visible.sync="ViewAuditFlag" width="70%" append-to-body :show-close="false" :close="ViewAuditFlagClose"
      custom-class="ViewAudit">
      <div slot="title">
        <el-row style="font-size: 18px;font-weight: bold;color:#606266;display: flex;">
          <div style="width: calc(100% - 33px);text-align: center;" class="ellipsis1">
            {{
              ViewAuditForm.ApprovalName
            }}
          </div>
          <div style="text-align: right;width: 33px;">
            <img style="width: 33px;height: 33px; margin-right: 10px;cursor: pointer;" src="@/assets/delete.png" alt=""
              @click="ViewAuditFlagClose" />
          </div>
        </el-row>
        <el-row style="font-size: 14px;font-weight: bold;color:#909399;text-align: left;width: calc(100% - 33px);"
          class="ellipsis">
          {{
            ViewAuditForm.ApprovalCode
          }}

        </el-row>
      </div>
      <el-row :gutter="10" v-loading.fullscreen.lock="ViewAuditLoadings">
        <el-col :span="9">
          <el-card>
            <div style="overflow-y: auto;height: 65vh;" class="viewList">
              <el-descriptions class="margin-top" :column="1" size="medium" border>
                <el-descriptions-item :label="addAuditType == 1 ? '设备/材料名称' : '深化图纸名称'">
                  {{ ViewAuditForm.ApprovalName }}
                </el-descriptions-item>
                <el-descriptions-item :label="addAuditType == 1 ? '设备/材料报审单编号' : '深化图纸报审单编号'">
                  {{ ViewAuditForm.ApprovalCode }}
                </el-descriptions-item>
                <el-descriptions-item label="报审批次">第<span style="color: #D1100B;">{{
                  ViewAuditForm.ApprovalBatch
                }}</span>批次
                </el-descriptions-item>
                <el-descriptions-item label="报审单位">{{ ViewAuditForm.ReportingUnit }}</el-descriptions-item>
                <el-descriptions-item label="报审人">{{ ViewAuditForm.ReportingName }}</el-descriptions-item>
                <el-descriptions-item label="报审日期"> {{
                  ViewAuditForm.ReportingDate ? ViewAuditForm.ReportingDate.substring(0, 19) : ''
                }}
                </el-descriptions-item>
                <el-descriptions-item label="关联附件">
                  <div v-for="item in ViewUpFileData" :key="item.Id"
                    style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                    @click="downloadFile(item)">
                    {{ item.FileName }}
                  </div>
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </el-col>
        <el-col :span="15">
          <el-card>
            <div class="content" style="overflow-y: auto;height: 65vh;">
              <div>
                <div v-for="item in ViewSignatoryUnitOptions" :key="item.Id">
                  <el-form label-width="120px" style="width: 80%;font-weight: bold;" class="ViewAuditForm" disabled>
                    <el-form-item :label="item.ApprovalOrganName + '：'">
                      <el-select v-model="item.ApprovalOpinions" clearable placeholder="未选择批复意见" style="width: 60%">
                        <el-option v-for="v in ApprovalOpinions" :key="v.DicName" :label="v.DicName"
                          :value="v.DicValue">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="批注意见：">
                      <el-select v-model="item.ApprovalAnnotation" clearable placeholder="未选择批注意见" style="width: 60%">
                        <el-option v-for="v in AnnotationOpinions" :key="v.DicName" :label="v.DicName"
                          :value="v.DicValue">
                        </el-option>
                      </el-select>
                      <el-input type="textarea" style="margin-top: 5px;" v-model="item.ApprovalARemark">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="关联附件：">

                      <div v-for="c in item.AuditUpFileData" :key="c.Id"
                        style="color: #D1100B;cursor: pointer;font-size: 14px;font-weight: bold;"
                        @click="downloadFile(c)" class="ellipsis">
                        {{ c.FileName }}
                      </div>


                    </el-form-item>
                    <el-form-item label="负责人签署：">
                      <div style="display: flex;align-items: center;">
                        <el-image style="width: 200px; height: 100px" :src="item.ApproverSignature">
                          <div slot="error"
                            style="width: 100%;height: 100%;text-align: center;line-height: 100px;background-color: #F5F7FA;">
                            暂无数据
                          </div>
                        </el-image>
                        <div style="margin-left: 10px;">
                          <el-date-picker v-model="item.ApprovalDateTime" type="date" value-format="yyyy-MM-dd "
                            format="yyyy-MM-dd " placeholder="未选择日期">
                          </el-date-picker>
                        </div>
                        <div style="margin-left: 20px;">
                          <el-tag v-if="item.ApprovalStatus == 1" type="success">
                            已审核
                          </el-tag>
                          <el-tag v-if="item.ApprovalStatus == 0" type="danger">
                            未审核或暂存
                          </el-tag>
                        </div>
                      </div>
                    </el-form-item>

                  </el-form>
                  <el-divider></el-divider>
                </div>
              </div>
              <!-- <div v-if="addAuditType == 2">
                <div v-for="item in ViewSignatoryUnitOptions" :key="item.value">
                  <el-form label-width="120px" style="width: 80%;font-weight: bold;" class="ViewAuditForm" disabled>

                    <el-form-item :label="item.ApprovalOrganName + '：'">
                      <div style="display: flex;">
                        <el-select v-model="item.ApprovalOpinions" clearable placeholder="意见归类"
                          style="width: 30%;display: inline-block;" size="small">
                          <el-option v-for="v in OpinionClassification" :key="v.DicName" :label="v.DicName"
                            :value="v.DicValue">
                          </el-option>
                        </el-select>
                        <el-input type="textarea" style="margin-top: 5px;display: inline-block;" placeholder="批注意见备注"
                          :rows="1" v-model="item.ApprovalARemark">
                        </el-input>
                        <div style="margin-left: 20px;">
                          <el-tag v-if="item.ApprovalOpinions" type="success">
                            已签署
                          </el-tag>
                          <el-tag v-if="!item.ApprovalOpinions" type="danger">
                            未签署
                          </el-tag>
                        </div>
                      </div>
                    </el-form-item>


                  </el-form>
                  <el-divider></el-divider>
                </div>
              </div> -->

            </div>

          </el-card>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ViewAuditFlagClose">取 消</el-button>

      </span>
    </el-dialog>
  </div>
</template>
<script>
import UploadValue from "@/components/UploadValue/UploadValue.vue";
import moment from "moment";
import vueQr from "vue-qr";

export default {
  data() {
    return {
      moment,
      qrcode: "",
      ViewAuditFlag: false,
      ViewAuditLoadings: false,
      ViewAuditForm: {},
      ViewUpFileData: [],
      ViewSignatoryUnitOptions: [],
      qrcodeVisible: false,
      code: "",//报审编号
      ApprovalUnit: {},
      ApprovalUnitUserInfo: {},
      ShopData: {},
      ShopId: "",
      rowId: "",
      addAuditLoadings: false,
      addAuditFlag: false,
      addIsDisabled: false,
      addAuditFlagForm: {
        ShopId: "",
        ApprovalType: "",
        ApprovalName: "",
        ApprovalCode: "",
        ApprovalBatch: "",
        ReportingName: "",
        ReportingDate: "",
        ReportingUnit: "",
        SignatoryUnit: '',
      },
      addAuditUpFileData: [],
      SignatoryUnitList: [],
      addAuditFlagRules: {
        ApprovalName: [
          { required: true, message: "未输入名称", trigger: "blur" },

        ],
        ApprovalCode: [
          { required: true, message: "未输入编号", trigger: "blur" },
        ],
        ApprovalBatch: [
          { required: true, message: "未输入批次", trigger: "change" },
        ],
        ReportingUnit: [
          { required: true, message: "未输入报审单位", trigger: "blur" },

        ],
        ReportingDate: [
          { required: true, message: "未选择报审日期", trigger: "change" },
        ],
        // SignatoryUnit: [
        //   { required: true, message: "请选择签署机构", trigger: "change" },
        // ],
        ReportingName: [
          { required: true, message: "未输入报审人", trigger: "change" },
        ],
        // addAuditUpFileData: [
        //   { required: true, message: "请上传附件", trigger: "blur" },
        // ],

      },
      AuditTitle: "",
      addAuditType: 0,//1设备材料，2深化图纸
      ApprovalOpinions: [],
      AnnotationOpinions: [],
      OpinionClassification: [],
      SignatoryUnitOptions: [],
      auditMaterialsInfoList: [],
      auditImgInfoList: []
    };
  },
  props: {},
  components: { UploadValue, vueQr },
  methods: {
    copyUrl(text) {
      var input = document.createElement("input"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    getShopData() {

      this.$http.post('/Shop_Manage/Shop_BaseInfo/GetTheData', {
        "id": this.ShopId
      }).then(res => {
        if (res.Success) {
          this.ShopData = res.Data
        } else {
          this.$message.error(res.Msg)
        }
      })
    },
    //附件上传成功后避免组件验证失效
    propValidateField() {
      this.SubSignatoryUnitList()
      setTimeout(() => {
        this.$refs["addAuditFlagForm"].validateField("addAuditUpFileData");
      }, 100);
    },
    errorValidateField(data) {
      console.log(data, 'errorValidateField')
      let str = ''
      data.forEach(item => {
        str = item.name + ',' + str
      })
      this.addAuditLoadings = false
      this.$message.error(str + '文件上传失败')
    },
    // 新增保存
    async addAuditSubmit() {


      // console.log(flag)
      // console.log(this.addAuditUpFileData[0], '2')
      console.log(this.addAuditFlagForm)

      this.$refs.addAuditFlagForm.validate((valid) => {
        if (valid) {
          let flag = this.SignatoryUnitList.every(item => {
            return item.ApprovalOrganName && (item.ApprovalUserType === 0 || item.ApprovalUserType === 1) && (/^1[3-9]\d{9}$/.test(item.ApprovalPhone))
          })
          if (!flag) {
            return this.$message.error('请填写正确的签署机构信息')
          }

          this.addAuditLoadings = true
          let obj = JSON.parse(JSON.stringify(this.addAuditFlagForm))
          if (this.addAuditType == 1) {
            obj.ApprovalCode = `M-${this.code ? this.code : this.padding1(this.auditMaterialsInfoList.length + 1, 4)}-${obj.ApprovalCode}`
          } else if (this.addAuditType == 2) {
            obj.ApprovalCode = `S-${this.code ? this.code : this.padding1(this.auditImgInfoList.length + 1, 4)}-${obj.ApprovalCode}`
          }

          this.$http
            .post("/Shop_Manage/Shop_ApprovalInfo/SaveData", {
              ...obj,
              ApprovalType: this.addAuditType,
              ShopId: this.ShopId,

            })

            .then(async (res) => {

              if (res.Success) {
                let data = res.Data
                this.rowId = data.Id
                this.code = res.Data.ApprovalCode.split('-')[1]
                res.Data.ApprovalCode = this.sliceStringAfterThirdValue(res.Data.ApprovalCode, '-')
                this.addAuditFlagForm = { ...res.Data }
                await setTimeout(async () => {
                  let flag = await this.$refs.UploadValue.upload()
                  console.log(flag, 'flag')
                  if (!flag) {
                    this.SubSignatoryUnitList()
                  }
                })
              } else {
                this.$message.error("新建报审信息失败");
                this.addAuditLoadings = false
              }
            });
        } else {
          return false;
        }
      });
    },
    //图审新建添加
    addSignatory(index) {
      this.SignatoryUnitList.splice(index + 1, 0, {
        ApprovalId: '',
        ApprovalOrganType: '',
        ApprovalOrganName: '',
        ApprovalUserType: 1,
        ApprovalPhone: '',
      })

    },
    delSignatory(index, item) {
      console.log(item, index)

      if (item.Id) {
        this.$confirm('此操作将删除该信息,确认删除吗', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/DeleteData', [item.Id]).then(res => {
            if (res.Success) {
              this.$message.success('删除成功')
              this.SignatoryUnitList.splice(index, 1)
            } else {
              this.$message.error(res.Msg)
            }
          })
        })
      } else {
        this.SignatoryUnitList.splice(index, 1)
      }

    },
    addAuditUpSuccess(FileList) {
      this.addAuditUpFileData = [...FileList]
      console.log(this.addAuditUpFileData, '11')
    },
    addAuditUpRemove(FileList, newFileListData) {
      this.addAuditUpFileData = [...FileList]
      console.log(FileList, newFileListData)
      console.log(this.addAuditUpFileData, '1122')
    },
    addAuditClose() {
      this.addAuditFlag = false;
    },
    addAuditOpen(num, item, isTrue) {
      this.addAuditLoadings = false
      if (item) {
        this.AuditTitle = '编辑'
      } else {
        this.AuditTitle = '新建'
      }

      if (isTrue) {
        this.AuditTitle = '查看'
        this.addIsDisabled = true
      } else {
        this.addIsDisabled = false
      }
      console.log(2)
      this.addAuditType = num
      this.addAuditFlag = true;
      this.addAuditUpFileData = []
      this.rowId = ''
      this.code = ''
      this.addAuditFlagForm = {
        ShopId: "",
        ApprovalType: "",
        ApprovalName: "",
        ApprovalCode: "",
        ApprovalBatch: "",
        ReportingName: "",
        ReportingDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        ReportingUnit: this.ApprovalUnit.ReportingUnit,
        SignatoryUnit: '',
      }
      this.addAuditLoadings = false
      this.SignatoryUnitList = [{
        ApprovalId: '',
        ApprovalOrganType: '',
        ApprovalOrganName: '',
        ApprovalUserType: 1,
        ApprovalPhone: '',
      }]
      this.getSignatoryUnitOptions()
      this.$nextTick(() => {
        this.$refs.addAuditFlagForm.resetFields();
        if (item) {
          this.getApprovalInfo(item)
        }
      });
    },
    //图审签署保存
    SubSignatoryUnitList() {

      let arr = [...this.SignatoryUnitList]
      arr.forEach(item => {
        item.ApprovalId = this.rowId
        item.ApprovalOrganType = item.ApprovalOrganName
      })
      this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/SaveDataByList', arr).then(res => {
        this.addAuditFlag = false;
        this.addAuditLoadings = false
        if (res.Success) {
          this.$message.success("新建报审信息成功");
          if (this.addAuditType == 1) {
            this.getDataListByMoreCondition(1)
          } else if (this.addAuditType == 2) {
            this.getDataListByMoreCondition(2)
          }
        } else {
          this.$message.error(res.Msg);
        }
      })
    },
    sliceStringAfterThirdValue(str, value) {
      // 获取第一个值的位置
      var index1 = str.indexOf(value);
      // 如果第一个值不存在，返回原字符串
      if (index1 === -1) return str;

      // 获取第二个值的位置
      var index2 = str.indexOf(value, index1 + value.length);
      // 如果第二个值不存在，返回原字符串
      if (index2 === -1) return str;

      // // 获取第三个值的位置
      // var index3 = str.indexOf(value, index2 + value.length);
      // // 如果第三个值不存在，返回从第二个值之后的部分
      // if (index3 === -1) return str.substring(index2 + value.length);

      // 截取从第三个值结束到字符串末尾的部分
      return str.substring(index2 + value.length);
    },

    //图审编辑详情
    async getApprovalInfo(item) {
      this.addAuditLoadings = true
      //主要信息详情
      let a = () => {
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetTheData', {
          "id": item.Id
        }).then(res => {
          if (res.Success) {
            // console.log(this.sliceStringAfterThirdValue(res.Data.ApprovalCode, '-'), '-------------')
            this.code = res.Data.ApprovalCode.split('-')[1]

            res.Data.ApprovalCode = this.sliceStringAfterThirdValue(res.Data.ApprovalCode, '-')

            this.addAuditFlagForm = { ...res.Data }

          }
        })
      }
      //附件详情
      let b = () => {
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo_FAL/GetDataListBySingleCondition', {
          "Search": {
            "Condition": "ApprovalId",
            "Keyword": item.Id
          }
        }).then(res => {
          if (res.Success) {
            res.Data.length
            if (res.Data.length > 0 && res.Data) {
              res.Data.forEach(item => {
                item.name = item.FileName
                item.size = item.FileSize
                item.url = item.FileWebURL
                item.uid = item.Id
                item.status = "success"
              })
              this.addAuditUpFileData = [...res.Data]
            } else {
              this.addAuditUpFileData = []
            }

          }
        })
      }
      //签署机构详情
      let c = () => {
        this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/GetDataListBySingleCondition', {
          "Search": {
            "Condition": "ApprovalId",
            "Keyword": item.Id
          }
        }).then(res => {
          if (res.Success) {
            if (res.Success) {
              res.Data.forEach(item => {
                item.vid = new Date().getTime()
              })
              if (res.Data.length > 0) {
                this.SignatoryUnitList = [...res.Data]
              } else {
                this.SignatoryUnitList = [{
                  ApprovalId: '',
                  ApprovalOrganType: '',
                  ApprovalOrganName: '',
                  ApprovalUserType: 1,
                  ApprovalPhone: '',
                }]
              }

            }
          }
        })
      }
      Promise.all([
        a(), b(), c()
      ]).then(() => {
        this.addAuditLoadings = false
      }).catch(() => {
        this.addAuditLoadings = false
      })

    },


    // 图审字典 	批复意见-内 1 材料 2 图纸
    getApprovalOpinions(type) {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: type == 1 ? 'ZS_ApprovalOpinions_CL_N' : 'ZS_ApprovalOpinions_TS_N',
        }).then(res => {
          if (res.Success) {
            this.ApprovalOpinions = res.Data.DictionaryDetailsList || []
          }
        })
    },
    // 图审字典 批注意见-内 1 材料 2 图纸
    getAnnotationOpinions(type) {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: type == 1 ? 'ZS_AnnotationOpinions_CL_N' : 'ZS_AnnotationOpinions_TS_N',
        }).then(res => {
          if (res.Success) {
            this.AnnotationOpinions = res.Data.DictionaryDetailsList || []
          }
        })
    },
    // 图审字典 //意见归类
    getOpinionClassification() {
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: 'ZS_OpinionClassification_TS_N',
        }).then(res => {
          if (res.Success) {
            this.OpinionClassification = res.Data.DictionaryDetailsList || []
          }
        })
    },
    //审批单位或部门改变
    changeSignatoryUnit(val) {
      let obj = this.SignatoryUnitOptions.find(item => item.DicValue === val)
      console.log(val)
      if (!obj) {
        let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
        if (selection.SignatoryUnitOptions) {
          selection.SignatoryUnitOptions.push({
            DicName: val,
            DicValue: val
          })
        } else {
          selection.SignatoryUnitOptions = [{
            DicName: val,
            DicValue: val
          }]
        }
        this.SignatoryUnitOptions.unshift({
          DicName: val,
          DicValue: val
        })
        window.sessionStorage.setItem('selection', JSON.stringify(selection))
      }
    },
    //图审字典 //审批部门
    getSignatoryUnitOptions() {
      let Keyword = ''
      if (this.addAuditType == 1) {
        Keyword = 'ZS_ExternalOrganization_CL_N'
      } else if (this.addAuditType == 2) {
        Keyword = 'ZS_ExternalOrganization_TS_N'
      }
      this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: Keyword,
        })
        .then(res => {
          if (res.Success) {
            this.SignatoryUnitOptions = res.Data.DictionaryDetailsList || []
            let selection = JSON.parse(window.sessionStorage.getItem('selection')) || {}
            if (selection.SignatoryUnitOptions) {
              this.SignatoryUnitOptions = [...selection.SignatoryUnitOptions, ...this.SignatoryUnitOptions]
            }
          }
        })
    },
    // 图审列表
    async getDataListByMoreCondition(ApprovalType) {
      await this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetDataListByMoreCondition', {
        "SortField": "CreateTime",
        "SortType": "desc",
        "Search": [
          {
            "Condition": "ShopId",
            "Keyword": this.ShopId,
          },
          {
            "Condition": "ApprovalType",
            "Keyword": ApprovalType,
          },
          {
            "Condition": "CreatorId",
            "Keyword": this.ApprovalUnitUserInfo.Id,
          }
        ]
      }).then(res => {
        if (res.Success) {
          if (ApprovalType == 1) {
            this.auditMaterialsInfoList = res.Data || []
          } else if (ApprovalType == 2) {
            this.auditImgInfoList = res.Data || []
          }


        }
      })
    },
    padding1(num, length) {
      for (var len = (num + "").length; len < length; len = num.length) {
        num = "0" + num;
      }
      return num;
    },
    changeApprovalName() {
      this.addAuditFlagForm.ApprovalCode = this.addAuditFlagForm.ApprovalName
    },
    AuditOpen(item, num) {
      if (num === 3) {
        this.qrcodeVisible = true
        this.qrcode = window.location.origin + '/ExternalUserLogin'
        return
      }
      this.$confirm('确认提审吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/Shop_Manage/Shop_ApprovalInfo/UpdateDataByCondition', {
          "Conditions": [
            {
              "Condition": "Status",
              "Keyword": "1"
            }
          ],
          "Ids": [
            item.Id
          ]
        }).then(res => {
          if (res.Success) {
            this.$message.success('提审成功')
            this.qrcodeVisible = true
            this.qrcode = window.location.origin + '/ExternalUserLogin'
            if (num == 1) {
              this.getDataListByMoreCondition(1)
            } else if (num == 2) {
              this.getDataListByMoreCondition(2)
            }
          } else {
            this.$message.error(res.Msg)
          }
        })
      })
    },
    AuditDelete(item, num) {
      this.$confirm('此操作将删除该信息,确认删除吗', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/Shop_Manage/Shop_ApprovalInfo/DeleteData', [item.Id]).then(res => {
          if (res.Success) {
            this.$message.success('删除成功')
            if (num == 1) {
              this.getDataListByMoreCondition(1)
            } else if (num == 2) {
              this.getDataListByMoreCondition(2)
            }
          } else {
            this.$message.error(res.Msg)
          }
        })
      })


    },
    editAuditOpen(item, num) {
      if (item.Status === 0) {
        this.addAuditOpen(num, item)
        return
      }
      console.log(item, 1)
      this.addAuditType = num
      this.getSignatoryUnitOptions()
      // if (num == 1) {
      //   this.getApprovalOpinions(num)
      //   this.getAnnotationOpinions(num)
      // } else if (num == 2) {
      //   this.getOpinionClassification()
      // }
      this.getApprovalOpinions(num)
      this.getAnnotationOpinions(num)
      this.ViewAuditFlag = true
      this.getViewAuditDataInfo(item)
      // this.ViewAuditForm = { ...item }
    },
    getViewAuditDataInfo(item) {
      this.ViewAuditLoadings = true
      let a = () => {
        this.ViewAuditForm = {}
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo/GetTheData', {
          id: item.Id
        }).then(res => {
          if (res.Success) {
            this.ViewAuditForm = res.Data || {}
          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      let b = () => {
        this.ViewUpFileData = []
        return this.$http.post('/Shop_Manage/Shop_ApprovalInfo_FAL/GetDataListByMoreCondition', {
          "Search": [
            {
              "Condition": "ApprovalId",
              "Keyword": item.Id
            }
          ]
        }).then(res => {
          if (res.Success) {
            if (res.Data && res.Data.length > 0) {
              res.Data.forEach(item => {
                item.name = item.FileName
                item.size = item.FileSize
                item.url = item.FileWebURL
                item.uid = item.Id
                item.status = "success"
              })
              this.ViewUpFileData = res.Data
            } else {
              this.ViewUpFileData = []
            }

          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      let c = () => {
        this.ViewSignatoryUnitOptions = []
        return this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo/GetDataListByMoreCondition', {
          Search: [
            {
              "Condition": "ApprovalId",
              "Keyword": item.Id
            },
          ]
        }).then(async res => {
          if (res.Success) {
            this.ViewSignatoryUnitOptions = res.Data || []
            for (const val of this.ViewSignatoryUnitOptions) {
              this.$set(val, 'AuditUpFileData', [])
              await this.getitemUpList(val)
            }


          } else {
            this.$message.error(res.Msg)
          }
        })
      }
      Promise.all([
        a(), b(), c()
      ]).then(() => {
        this.ViewAuditLoadings = false
      }).catch(() => {
        this.ViewAuditLoadings = false
      })


    },
    async getitemUpList(item) {
      await this.$http.post('/Shop_Manage/Shop_ApprovalSignInfo_FAL/GetDataListBySingleCondition', {
        "Search":
        {
          "Condition": "ApprovalSignId",
          "Keyword": item.Id
        }

      }).then(res => {
        if (res.Success) {
          res.Data.forEach(val => {
            val.name = val.FileName
            val.size = val.FileSize
            val.url = val.FileWebURL
            val.uid = val.Id
            val.status = "success"
          })
          this.$set(item, 'AuditUpFileData', res.Data)
        } else {
          this.$phoneMessage({
            message: '附件列表获取失败',
          })
        }
      })

    },
    ViewAuditFlagClose() {
      this.ViewAuditFlag = false
    },
    downloadFile(item) {
      window.open(item.FileWebURL)
    },

  },
  mounted() {

  },
  created() {
    this.ShopId = window.localStorage.getItem('ShopId') || ''
    this.ApprovalUnit = JSON.parse(window.localStorage.getItem('ApprovalUnit')) || {}

    this.ApprovalUnitUserInfo = JSON.parse(window.localStorage.getItem('ApprovalUnitUserInfo')) || {}
    console.log(this.ApprovalUnitUserInfo, 'ApprovalUnitUserInfo')
    console.log(this.ApprovalUnit, 'ApprovalUnit')
    this.getShopData()
    this.getDataListByMoreCondition(1)
    this.getDataListByMoreCondition(2)
  },
  computed: {
    SignatoryUnitOptionsLength() {

      if (this.SignatoryUnitList.length === 1) {
        return true
      } else {
        return false
      }

    }
  },
  watch: {},
};
</script>
<style lang='scss' scoped>
.Shop_SubmitApprovalList {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.div {
  margin: 0;
  line-height: 80px;
  font-weight: bold;
  font-size: 18px;
}

::v-deep .deepCard {
  height: calc(100vh - 100px);
  overflow-y: auto;

  .el-descriptions-item__label {
    width: 30%;
    text-align: right;
    font-size: 16px !important;
    font-weight: bold;
  }
}


::v-deep .el-form-item__label {
  font-size: 18px;
}

::v-deep .addAudit {
  margin-top: 5vh !important;
}

.ellipsis {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 2; //内容限制的行数
}

.auditMaterialsInfoList:hover {
  cursor: pointer;
  background: #f3eaea;
}

.ellipsis1 {
  overflow: hidden; //超出隐藏
  word-wrap: break-word;
  word-break: normal;
  text-overflow: ellipsis; //文本超出时显示省略号

  display: -webkit-box;

  -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

  -webkit-line-clamp: 1; //内容限制的行数
}

.viewCard {
  height: 60vh;
}

::v-deep .ViewAudit {
  margin-top: 5vh !important;

  .el-dialog__body {
    padding: 0px 20px;
  }
}

::v-deep .viewList {
  .el-descriptions-item__label {
    width: 40%;
    text-align: right;
    font-size: 16px !important;
    font-weight: bold !important;
  }
}
</style>
